import classNames from 'classnames'
import { format } from 'date-fns'
import xss from 'xss'

import { Carousel } from './carousel'
import { Sections } from './sections'
import { Subnav } from './subnav'
import { toSnakeCase } from '~/utils/to-snake-case'
import {
  Website_Block,
  Website_Config,
  Website_Event,
  Website_Page,
} from '~/website.models'

export function Page({
  blocks,
  config,
  events,
  page,
  static_content,
  showMenu,
}: {
  blocks?: Website_Block[]
  config?: Website_Config[]
  events?: Website_Event[]
  page?: Website_Page
  static_content?: string
  showMenu: (value: boolean) => undefined
}) {
  const sections = page?.sections ?? []
  const calloutSections = []
  const nonCalloutSections = []

  for (const section of sections) {
    if (section.is_callout) {
      calloutSections.push(section)
    } else {
      nonCalloutSections.push(section)
    }
  }

  const heroImages = page?.hero_images ?? []

  const locationHtml =
    blocks?.find(block => block.type === 'location')?.html ?? ''

  const hoursHtml = blocks?.find(block => block.type === 'hours')?.html ?? ''

  return (
    <>
      {heroImages.length > 0 ? <Carousel heroImages={heroImages} /> : null}

      {page?.type === 'home' && config?.[0]?.message_enabled && (
        <div className="message">{config?.[0]?.message_html}</div>
      )}

      {page?.show_sub_nav ? <Subnav sections={sections}></Subnav> : null}

      {page?.type === 'contact' && (
        <div className="section contact">
          <h1 className="above center">Location &amp; Hours</h1>

          <div className="container split">
            <div className="section-col">
              <span
                dangerouslySetInnerHTML={{ __html: xss(locationHtml) }}
              ></span>
            </div>

            <div className="section-col">
              <span dangerouslySetInnerHTML={{ __html: xss(hoursHtml) }}></span>
            </div>
          </div>
        </div>
      )}

      <Sections sections={sections} showMenu={showMenu} />

      {page?.type === 'events' && events
        ? [...events].map((e, index) => {
            const date = new Date(e.start_date)

            if (e.start_time) {
              const time = e.start_time.split(':')

              date.setHours(parseInt(time[0]))
              date.setMinutes(parseInt(time[1]))
            }

            return (
              <div
                key={index}
                id={e.title ? toSnakeCase(e.title) : undefined}
                className={classNames('section events', {
                  alt: index % 2 === 0,
                })}
              >
                <div className="container split">
                  {e.image_url ? (
                    <div className="section-col">
                      <img
                        className="section-img"
                        src={e.image_url}
                        alt={e.title}
                        style={
                          e.image_radius
                            ? { borderRadius: e.image_radius + 'px' }
                            : {}
                        }
                      />
                    </div>
                  ) : null}

                  <div className="section-col">
                    <h1>{e.title}</h1>
                    <div className="blurb-date">
                      {e.start_date ? (
                        <>{format(date, 'LLL dd, yyyy')}</>
                      ) : null}
                      {e.start_time ? ` @ ${format(date, 'h:mm a')}` : null}
                    </div>

                    <div
                      className="blurb"
                      dangerouslySetInnerHTML={{
                        __html: xss(e.description || ''),
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            )
          })
        : null}

      {static_content ? (
        <div className="page-content">
          <div
            className="container"
            dangerouslySetInnerHTML={{ __html: static_content }}
          ></div>
        </div>
      ) : null}
    </>
  )
}
