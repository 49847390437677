/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames'
import xss from 'xss'

import { toSnakeCase } from '~/utils/to-snake-case'
import { Website_Section } from '~/website.models'

export function Sections({
  sections,
  showMenu,
}: {
  sections: Website_Section[]
  showMenu: (value: boolean) => undefined
}) {
  return sections && sections.length ? (
    <>
      {sections.map((section, index) => (
        <div
          className={classNames({
            alt: index % 2 === 0,
            callout: section.is_callout,
            custom: !section.is_callout && section.is_custom,
            section: !section.is_callout,
          })}
          key={index}
          id={section.title ? toSnakeCase(section.title) : undefined}
          style={{
            ...(section.bg_color && { backgroundColor: section.bg_color }),
            ...(section.padding && { padding: section.padding + 'px 0' }),
            ...(section.text_color && { color: section.text_color }),
          }}
        >
          {section.is_custom ? (
            <>
              {section.title && <h1>{section.title}</h1>}
              {section.subtitle && (
                <div className="section-subtitle">{section.subtitle}</div>
              )}
              {section.html && (
                <div
                  dangerouslySetInnerHTML={{ __html: section?.html || '' }}
                ></div>
              )}
            </>
          ) : (
            <>
              {section.is_callout ? (
                <div className="container">
                  {section.title_above && (
                    <>
                      {section.subtitle && (
                        <div className="callout-subtitle above">
                          {section.subtitle}
                        </div>
                      )}
                    </>
                  )}

                  <button className="btn" onClick={() => showMenu(true)}>
                    Draft List
                  </button>

                  {section.cta_url && (
                    <>
                      <span className="callout-symbol">★</span>

                      <a
                        href={`https://${section.cta_url}`}
                        className="btn"
                        {...(section.cta_new_window
                          ? { target: '_blank' }
                          : {})}
                      >
                        {section.cta_label ?? ''}
                      </a>
                    </>
                  )}

                  {section.subtitle && !section.title_above && (
                    <div className="callout-subtitle">{section.subtitle}</div>
                  )}
                </div>
              ) : (
                <>
                  {section.title_above && (
                    <>
                      {section.title && <h1>{section.title}</h1>}

                      {section.subtitle && (
                        <div className="section-subtitle">
                          {section.subtitle}
                        </div>
                      )}
                    </>
                  )}

                  <div className="container split">
                    {section.image_url ? (
                      <div className="section-col">
                        <img
                          className="section-img"
                          src={section.image_url}
                          alt={section.title}
                          style={
                            section.image_radius
                              ? { borderRadius: section.image_radius + 'px' }
                              : {}
                          }
                        />
                      </div>
                    ) : null}

                    <div className="section-col">
                      {!section.title_above ? (
                        <>
                          {section.title && <h1>{section.title}</h1>}

                          {section.subtitle && (
                            <div className="section-subtitle">
                              {section.subtitle}
                            </div>
                          )}
                        </>
                      ) : null}

                      <div
                        className="blurb"
                        dangerouslySetInnerHTML={{
                          __html: xss(section.html || ''),
                        }}
                      ></div>

                      {section.cta_url && (
                        <a
                          href={`https://${section.cta_url}`}
                          className="btn small"
                          {...(section.cta_new_window
                            ? { target: '_blank' }
                            : {})}
                        >
                          {section.cta_label ?? ''}
                        </a>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ))}
    </>
  ) : null
}
